<template>
  <vx-card class="pl-3">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-2"
            :options="limitOptions"
            :clearable="false"
          />
          <!--        <vs-button align="right">Create Template</vs-button>-->
        </div>
        <br />
        <template slot="thead">
          <vs-th sort-key="title">Template Name</vs-th>
          <vs-th sort-key="action">Edit</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].subject">
              {{ data[indextr].subject }}
            </vs-td>

            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <!-- <vs-button
                type="border"
                size="small"
                @click="editDetailHandler(data[indextr]._id)"
                icon-pack="feather"
                icon="icon-edit"
              ></vs-button> -->
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <feather-icon
                    icon="Edit3Icon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="editDetailHandler(data[indextr]._id)"
                  />
                </vx-tooltip>
              </vs-row>
            </vs-td>
            <!--
          <vs-td :data="data[indextr]._id">
            <vs-radio v-model="radios2" vs-value="Disable" color="danger"></vs-radio>
          </vs-td>-->
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 50,
      },
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
      radios2: "primary",
    };
  },
  methods: {
    ...mapActions("email", ["fetchEmails"]),
    moment(date) {
      return moment(date);
    },
    getEmailsList() {
      let self = this;
      this.fetchEmails(self.dataTableParams).then((res) => {
        
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getEmailsList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getEmailsList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getEmailsList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "edit-email-template", params: { id: id } });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getEmailsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getEmailsList();
      }
    },
  },
  created() {
    this.getEmailsList();
  },
};
</script>
